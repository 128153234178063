/* global.css */

/* Import the base Mantine styles */
@import '@mantine/core/styles.css';
@import '@mantine/dates/styles.css';
@import '@mantine/notifications/styles.css';
/* Reset and Normalize (If not already addressed by Mantine) */

body {
  font-family: 'Amiri', serif; /* Applies your theme's font */
  direction: rtl; /* Sets right-to-left text direction */
  line-height: 1.6; /* Default line spacing for readability */
  background-color: #FAFAFA; /* Off-White for primary app background */
  color: #36454F; /* Charcoal Gray for body text */
}

/* Headings (Tweak if needed) */
h1, h2, h3, h4, h5, h6 { 
  font-family: 'Amiri', serif; /* Applies your theme's font */ 
  color: #2C3E50; /* Navy Blue for headers */
}

/* Responsive Images (Mantine likely covers this, but double-check) */
img {
  max-width: 100%; 
  height: auto;  
}

/* Customizations */

/* Button Styles */
button {
  background-color: #2C3E50; /* Navy Blue for primary interactions */
  color: #FAFAFA; /* Off-White for button text */
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #d0fade; /* Dark Green for hover state */
}

/* Link Styles */
a {
  color: #1E5631; /* Dark Green for links */
  text-decoration: none;
}

a:hover {
  color: #DAA520; /* Subtle Gold for hovered links */
}

/* Input Styles */
input, textarea, select {
  background-color: #F0F0F0; /* Light Gray for form fields */
  color: #36454F; /* Charcoal Gray for input text */
  border: 1px solid #DAA520; /* Subtle Gold for focused input */
  padding: 8px;
  border-radius: 4px;
}

input:focus, textarea:focus, select:focus {
  border-color: #DAA520; /* Subtle Gold for focus highlight */
}

/* Media Queries - Adjust breakpoints according to Mantine defaults and your needs */
@media (max-width: 768px) {
  /* Responsive adjustments */
  body {
    padding: 10px;
  }
}
